import React, {useEffect} from 'react'

import { AppBar, Avatar, Box, 
         FormControlLabel, 
         Link, 
         Toolbar,
         Typography} from '@mui/material'
 import logo from "../assets/images/Bylogo.png";

import { useSelector, useDispatch } from 'react-redux';
import {toggleTheme, getTheme} from "../redux/actions/uiAction";
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Portfolio from './Portfolio';
import Footer from './Footer';
import user from "../assets/images/user.png"
import "../router/index";
import Resume from './Resume';
import Certificates from './Certificates';

//styling

const style = {
    //helper
    marginTop:{
        marginTop: 3
    },
    root:{
      backgroundColor:(theme)=> theme.palette.background.default,
      display:'flex',
      flexDirection:'column',
      justifyContent:'center'
    },
    menuLink:{
      display:'flex',
      color: theme => theme.palette.text.primary,
        fontSize:{
          xs:"1rem",
          sm: "1.2rem",
          md: "1.4rem"
      },
        marginLeft: 2,
        "&:hover":{
            color: theme => theme.palette.secondary.main,
        },
    },
    headerBackground: {
            backgroundImage: 'url(hay.png)',
            backgroundRepeat: 'no-repeat',
            backgroundSize:'cover',
            padding:'70px '
        },
    headingStyle1:{
      paddingTop: 7,
        fontWeight: 300,
        color: theme => theme.palette.text.primary,
        fontSize:{
            xs:"3.5rem",
            sm: "3.7rem",
            md: "5.5rem"
        },
        fontFamily: "RampartOne",

    },
    
    subtitle1:{
        fontWeight: 'bold',
        color: theme => theme.palette.text.primary,
        fontSize:{
            xs:"1rem",
            sm: "1.2rem",
            md: "1.5rem"
        },
        fontFamily: "Irish Grover",
    },
    section1:{
        display:"flex",
        flexDirection: "column",
        alignItems: "center",
    },
    section2:{
      color: theme => theme.palette.text.secondary,
        padding: "30px 0px",
        backgroundColor:(theme)=> theme.palette.background.default,
    },
    logo:{
        height: 100,
        width: 100,
        marginLeft: 1
    },
    typo:{
      fontFamily: "Cutie-Zombie",
      fontSize:{
        xs:"2rem",
        sm: "1.5rem",
        md: "1.8rem"
    },
    },


    };

//switch
const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));

export default function Index() {

 const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);

useEffect(() => {
  dispatch(getTheme());
}, [dispatch]);

const togglebtn = () => {
  dispatch(toggleTheme(!ui.isDarkMode));
}

    return (
        <Box sx={style.root}>
          <AppBar position="static" color="transparent">
              <Toolbar>
                  <Avatar src={logo} sx={style.logo}/>
                  <Box component="span" sx={{flexGrow : 1}} />
                  <Link a href="#/resume"  underline="none" sx={style.menuLink}>Resume</Link>
                  <Link a href="#/portfolio" underline="none" sx={style.menuLink}>Portfolio</Link>
                  <Link a href="#/certificates" underline="none" sx={style.menuLink}>Certificates</Link>
                  <Link a href="#/footer" underline="none" sx={style.menuLink}>Contact</Link>
                  <FormControlLabel
                   control={<MaterialUISwitch sx={{ m: 3 }} checked={!ui.isDarkMode} />}
                     label=""
                     onClick={togglebtn}
      />
              </Toolbar>
          </AppBar>
          
          <Box sx={style.headerBackground}> 
          <Box sx={{display:'flex', flexDirection: 'row'}}>
           <Avatar src={user} sx={{width: 450,
             height: 450,display:'flex', justifyContent:'flex-start',}}/>
          <Box sx={{...style.section1}}>
              <Typography variant="h1" color="textPrimary"
              sx={{...style.headingStyle1, ...style.marginTop}}>
                 Ma. Emerlita Orbiso
              </Typography>
              <Typography variant="subtitle1" color="textPrimary"  
              sx={{...style.subtitle1, ...style.marginTop
              }}>
                  Full Stack Developer/Programmer
              </Typography>
          </Box>
          </Box>
          </Box>
          <Box sx={style.section2}>
          <Box component="a" id="/resume"> <Resume/></Box>
          </Box>
          <Box component="a" id="/portfolio"><Portfolio /></Box>
          <Box component="a" id="/certificates"><Certificates /></Box>
          <Box component="a" id="/footer"><Footer/></Box>
          
        </Box>
    )
}