import React from 'react'
import { Divider, FormControlLabel, Slide, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import SwitchUnstyled, { switchUnstyledClasses } from '@mui/core/SwitchUnstyled';
import { c1, c2, c3, c4, c5,
    c6, c7, c8, c9, c10, c11, c12} from "../assets/certificates/index"
import QR from "../assets/certificates/QR.PNG"
const style = {
      port1:{
        padding: '50px 0',
        display:'flex',
        flexDirection:'row',
        justifyContent: 'center',
        fontFamily: "BreeSerif",
        fontWeight: 30,
        fontSize:{
          xs:"2rem",
          sm: "2.3rem",
          md: "2.7rem"
      },
      color: theme => theme.palette.text.primary,
  },
  photo:{
      height: 300,
      width: 320,
      marginLeft: 5,
      marginTop: 5
  }
}
const Root = styled('span')`
font-size: 2;
position: relative;
display: inline-block;
width: 32px;
height: 20px;
margin: 10px;
cursor: pointer;

&.${switchUnstyledClasses.disabled} {
  opacity: 0.4;
  cursor: not-allowed;
}

& .${switchUnstyledClasses.track} {
  background: #51a0a8;
  border-radius: 10px;
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
}

& .${switchUnstyledClasses.thumb} {
  display: block;
  width: 14px;
  height: 14px;
  top: 3px;
  left: 3px;
  border-radius: 16px;
  background-color: #fff;
  position: relative;
  transition: all 200ms ease;
}

&.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
  background-color: rgba(225, 255, 255, 1);
  box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
}

&.${switchUnstyledClasses.checked} {
  .${switchUnstyledClasses.thumb} {
    left: 14px;
    top: 3px;
    background-color: #fff;
  }

  .${switchUnstyledClasses.track} {
    background: #9660a8;
  }
}

& .${switchUnstyledClasses.input} {
  cursor: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  margin: 0;
}
`;
export default function Certificates() {
  const label = { componentsProps: { input: { 'aria-label': 'Demo switch' } } };
  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  }
    return (
      <Box>
      <Divider />
      <FormControlLabel
      
      label={<Typography sx={style.port1}>Certificates 
      </Typography>}
      control={ <SwitchUnstyled sx={{marginLeft: 5}} component={Root} {...label} checked={checked}
       onChange={handleChange} /> }
     / > <br />
     <Box component='img' src={QR} sx={{marginLeft: 5, height: 170, width: 170}}/>
     <Typography sx={{marginLeft: 4}}> Scan for my certificates</Typography>
        < br/>
        <Slide direction="right" in={checked} mountOnEnter unmountOnExit>
       <Box component='img' src={c1} sx={style.photo} ></Box>
       </Slide>
       <Slide direction="right" in={checked} mountOnEnter unmountOnExit>
       <Box component='img' src={c2} sx={style.photo} ></Box>
       </Slide>
       <Slide direction="left" in={checked} mountOnEnter unmountOnExit>
       <Box component='img' src={c3} sx={style.photo} ></Box>
       </Slide>
       <Slide direction="left" in={checked} mountOnEnter unmountOnExit>
       <Box component='img' src={c4} sx={style.photo} ></Box>
       </Slide>
       <Slide direction="right" in={checked} mountOnEnter unmountOnExit>
       <Box component='img' src={c5} sx={style.photo} ></Box>
       </Slide>
       <Slide direction="right" in={checked} mountOnEnter unmountOnExit>
       <Box component='img' src={c6} sx={style.photo} ></Box>
       </Slide>
       <Slide direction="left" in={checked} mountOnEnter unmountOnExit>
       <Box component='img' src={c7} sx={style.photo} ></Box>
       </Slide>
       <Slide direction="left" in={checked} mountOnEnter unmountOnExit>
       <Box component='img' src={c8} sx={style.photo} ></Box>
       </Slide>
       <Slide direction="up" in={checked} mountOnEnter unmountOnExit>
       <Box component='img' src={c9} sx={style.photo} ></Box>
       </Slide>
       <Slide direction="up" in={checked} mountOnEnter unmountOnExit>
       <Box component='img' src={c10} sx={style.photo} ></Box>
       </Slide>
       <Slide direction="up" in={checked} mountOnEnter unmountOnExit>
       <Box component='img' src={c11} sx={style.photo} ></Box>
       </Slide>
       <Slide direction="up" in={checked} mountOnEnter unmountOnExit>
       <Box component='img' src={c12} sx={style.photo} ></Box>
       </Slide>

      
    </Box>
    )
}
