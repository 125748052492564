import React from 'react'
import {Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot,} from '@mui/lab';
import {CastForEducation,
   LaptopMac,
   Hotel,
   } from '@mui/icons-material'
import { Divider, FormControlLabel, Slide, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import Chart from 'react-google-charts';
import SwitchUnstyled, { switchUnstyledClasses } from '@mui/core/SwitchUnstyled';

const style = {
    //helper
    typo:{
        color: theme => theme.palette.text.primary,
        fontFamily: "MargareteDemoRegular",
        fontSize:{
          xs:"2rem",
          sm: "1.5rem",
          md: "1.8rem"
      },
    },
      port1:{
        display:'flex',
        flexDirection:'row',
        justifyContent: "center",
        fontFamily: "BreeSerif",
        fontWeight: 30,
        fontSize:{
          xs:"3rem",
          sm: "3.1rem",
          md: "3.4rem"
      },
      color: theme => theme.palette.text.primary,
  },
}
const Root = styled('span')`
font-size: 0;
position: relative;
display: inline-block;
width: 32px;
height: 20px;
margin: 10px;
cursor: pointer;

&.${switchUnstyledClasses.disabled} {
  opacity: 0.4;
  cursor: not-allowed;
}

& .${switchUnstyledClasses.track} {
  background: #51a0a8;
  border-radius: 10px;
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
}

& .${switchUnstyledClasses.thumb} {
  display: block;
  width: 14px;
  height: 14px;
  top: 3px;
  left: 3px;
  border-radius: 16px;
  background-color: #fff;
  position: relative;
  transition: all 200ms ease;
}

&.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
}

&.${switchUnstyledClasses.checked} {
  .${switchUnstyledClasses.thumb} {
    left: 14px;
    top: 3px;
    background-color: #fff;
  }

  .${switchUnstyledClasses.track} {
    background: #9660a8;
  }
}

& .${switchUnstyledClasses.input} {
  cursor: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  margin: 0;
}
`;
export default function Resume() {
  const label = { componentsProps: { input: { 'aria-label': 'Demo switch' } } };
  const [checked, setChecked] = React.useState(true);

  const handleChange = () => {
    setChecked((prev) => !prev);
  }
    return (
      <Box>
      <Divider />
      <FormControlLabel
      label={<Typography sx={style.port1}>Resume</Typography>}
      control={ <SwitchUnstyled sx={{marginLeft: 5}} component={Root} {...label} checked={checked}
       onChange={handleChange} /> }
     / >
        <Timeline position="alternate" >
      <TimelineItem >
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot>
            <CastForEducation />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <Slide direction="left" in={checked} mountOnEnter unmountOnExit>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
        <Box sx={{border: 1, borderRadius:'16px'}}>
          <Box sx={{marginLeft: 5}}>
          <Typography sx={style.typo} variant="h3" component="span">
            EDUCATION
          </Typography>
          <Typography>&bull; 2005 - Kindergarden at Santo Angel Elementary School</Typography>
          <Typography>&bull; 2006 - 2008 - Grade 1 - 3 at Santo Angel Elementary School</Typography>
          <Typography>&bull; 2008 - 2012 - Grade 3 - 6  at Pulong Sampaloc Elementary School</Typography>
          <Typography>&bull; 2012 - 2016 Junior HighSchool at Dona Remedios Trinidad HighSchool</Typography>
          <Typography>&bull; 2016 - 2018 Senior HighSchool at Dona Remedios Trinidad HighSchool
              <br/>&#9734; (General Academic Stand)</Typography>
          <Typography>&bull; 2018 - 2022 College at Bulacan State University- Bustos Campus 
              <br/>&#9734; (Bachelor of Science in Information Technology)</Typography>
              </Box>   
              </Box>
        </TimelineContent>
       </Slide>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
            <LaptopMac />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <Slide direction="right" in={checked} mountOnEnter unmountOnExit>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
        <Box sx={{border: 1, borderRadius:'16px'}}>
          <Box sx={{marginRight: 5}}>
          <Typography sx={style.typo} variant="h4" component="span">
           Workshop and Training
          </Typography>
          <Typography >Upskill I.T Workshop at Upskill Information Technology Company</Typography>
          <Typography>Java Programming &#9734;</Typography>
          <Typography>Web Development using Wordpress &#9734;</Typography>
          <Typography>Computer Formatting &#9734;</Typography>
          <Typography>Ethical Hacking &#9734;</Typography>
          </Box>
          </Box>
        </TimelineContent>
        </Slide>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary" variant="outlined">
            <Hotel />
          </TimelineDot>
          <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
        </TimelineSeparator>
        <Slide direction="left" in={checked} mountOnEnter unmountOnExit>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
        <Box sx={{border: 1, borderRadius:'16px'}}>
          <Box sx={{padding: 2}}>
          <Typography sx={style.typo} variant="h4" component="span">
          Technical Skills
          </Typography>
          <Chart
                display='flex'
                chartType="BarChart"
                loader={<div>Loading Chart</div>}
                data={[
                  ['Skills', ''],
                  ['HTML5', 100],
                  ['CSS3', 90],
                  ['Material UI', 95],
                  ['JavaScript', 80],
                  ['jQuery', 70],
                  ['React Js', 95],
                  ['ReactNative', 90],
                  ['Node', 80 ],
                  ['PHP', 60],
                  ['XML',  60],
                  ['Python', 40],
                  ['SQL', 59],
                  ['Firebase', 95],
                ]}
                options={{
                  backgroundColor:'none',
                  colors:['#889EAF'],
                  legend: 'none',
                  chartArea: { width: '50%',height:'100%' },
                  isStacked: true,
                  hAxis: {
                    textStyle: { color: '#113CFC' },
                  },
                  vAxis: {
                  },
                  annotations: {
                    textFontSize: 20,
                  textStyle: {
                      color: '#FFF'
                    },
                  }
                }}
              />
          </Box>
          </Box>
        </TimelineContent>
        </Slide>
      </TimelineItem>
      </Timeline>
    </Box>
    )
}
