import * as React from 'react';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import { 
  Divider,
  Grow,
  Hidden,
  ImageList, ImageListItem, 
  ImageListItemBar, 
  ListSubheader, 
  Typography } from '@mui/material';
  import { SocialIcon } from 'react-social-icons';
  import {img1, img2, img3, img4, img5, img6} from "../assets/weather/index"
  import {ct1, ct2} from "../assets/chat-app/index"
  import {g1, g2, g3, g4, g5} from "../assets/ordering/index"
import { styled } from '@mui/system';
import SwitchUnstyled, { switchUnstyledClasses } from '@mui/core/SwitchUnstyled';
import {m1, m2} from "../assets/Awake/index"


const style = {
  root:{
    padding: '50px 0',
    backgroundColor:theme=> theme.palette.background.default,
  },
  subs:{
    color: theme => theme.palette.text.primary,
  },
  typos:{
    marginLeft: 2,
    backgroundColor:(theme)=> theme.palette.background.default,
    fontFamily: "Irish Grover",
    fontSize:{
      xs:"2rem",
      sm: "1.5rem",
      md: "1.8rem"
  },

  },
  port:{
        display:'flex',
        flexDirection:'row',
        justifyContent: "center",
        fontFamily: "Irish Grover",
        backgroundColor:(theme)=> theme.palette.background.default,
        
  },
  port1:{
        display:'flex',
        flexDirection:'row',
        justifyContent: "center",
        fontFamily: "Fruktur",
        fontWeight: 50,
        fontSize:{
          xs:"3rem",
          sm: "3.1rem",
          md: "3.4rem"
      },
      color: theme => theme.palette.text.primary,
  },

  
}
const Root = styled('span')`
font-size: 0;
position: relative;
display: inline-block;
width: 32px;
height: 20px;
margin: 10px;
cursor: pointer;

&.${switchUnstyledClasses.disabled} {
  opacity: 0.4;
  cursor: not-allowed;
}

& .${switchUnstyledClasses.track} {
  background: #51a0a8;
  border-radius: 10px;
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
}

& .${switchUnstyledClasses.thumb} {
  display: block;
  width: 14px;
  height: 14px;
  top: 3px;
  left: 3px;
  border-radius: 16px;
  background-color: #fff;
  position: relative;
  transition: all 200ms ease;
}

&.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
}

&.${switchUnstyledClasses.checked} {
  .${switchUnstyledClasses.thumb} {
    left: 14px;
    top: 3px;
    background-color: #fff;
  }

  .${switchUnstyledClasses.track} {
    background: #9660a8;
  }
}

& .${switchUnstyledClasses.input} {
  cursor: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  margin: 0;
}
`;
  const itemData = [
    {
      img: img1,
      title: 'Sign In',
      author: '@Weather-app by EmRbs',
      rows: 2,
      featured: true,
      
    },
    {
      img: img2,
      title: 'Sign-Up',
      author: '@Weather-app by EmRbs',
    },
    {
      img: img3,
      title: 'Validation',
      author: '@Weather-app by EmRbs',
    },
    {
      img: img4,
      title: 'Weather App',
      author: '@Weather-app by EmRbs',
    },
    {
      img: img5,
      title: 'Geolocation',
      author: '@Weather-app by EmRbs',
    },
    {
      img: img6,
      title: 'weather',
      author: '@Weather-app by EmRbs',
    },

  ]
  const chatapp =[
    {
      img: ct2,
      title: 'Sign In',
      author: '@Chat-App by EmRbs ',
      rows: 2,
      featured: true,
    },
    {
      img: ct1,
      title: 'Message',
      author: '@Chat-App by EmRbs',
    },
  ]
  const ordering =[
    {
      img: g4,
      title: 'get Started',
      author: '@Ordering by EmRbs ',
      rows: 2,
      featured: true,
    },
    {
      img: g1,
      title: 'Login',
      author: '@Ordering by EmRbs',
    },
    {
      img: g2,
      title: 'Order',
      author: '@Ordering by EmRbs',
    },
    {
      img: g3,
      title: 'Payment',
      author: '@Ordering by EmRbs',
    },
    {
      img: g5,
      title: 'Update',
      author: '@Ordering by EmRbs',
    },
  ]
  const awake =[
    {
      img: m1,
      title: 'Sign In',
      author: '@Awake by EmRbs ',
      rows: 2,
      featured: true,
    },
    {
      img: m2,
      title: 'Sign up',
      author: '@Awake by EmRbs',
    },
  ]
export default function Portfolio() {
  const label = { componentsProps: { input: { 'aria-label': 'Demo switch' } } };
  
  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  return (
    <Box>
      <Divider />
      <FormControlLabel sx={style.port}
      label={<Typography sx={style.port1}>Portfolio</Typography>}
      control={ <SwitchUnstyled component={Root} {...label} checked={checked}
       onChange={handleChange} /> }
     / >
      <Box sx={{ display: 'flex'}}>
        <Grow in={checked}>
        <ImageList sx={{ width: '100%', height: 300 }}>
        <ImageListItem key="Subheader" cols={2}>
        <ListSubheader component="div"sx={style.typos}>Weather App
        <SocialIcon url="https://main.weather-app-y7h.pages.dev/" 
        network="codepen" style={{ height: 30, width: 30, marginLeft: 5 }}/> </ListSubheader>
      </ImageListItem>
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=248&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            title={item.title}
            subtitle={item.author}
          />
        </ImageListItem>
      ))}
    </ImageList>
       </Grow>
       <Grow in={checked}  style={{ transformOrigin: '0 0 0' }}
          {...(checked ? { timeout: 1000 } : {})}
        >
        <ImageList sx={{ width: '100%', height: 300 }}>
        <ImageListItem key="Subheader" cols={2}>
        <ListSubheader component="div"sx={style.typos}>Ordering
        <SocialIcon network="codepen" style={{ height: 30, width: 30, marginLeft: 5 }}/> </ListSubheader>
      </ImageListItem>
      {ordering.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=248&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            title={item.title}
            subtitle={item.author}
          />
        </ImageListItem>
      ))}
    </ImageList>
        </Grow>
        <Grow in={checked}  style={{ transformOrigin: '0 0 0' }}
          {...(checked ? { timeout: 2000 } : {})}
        >
        <ImageList sx={{ width: '100%', height: 300, }}>
        <ImageListItem key="Subheader" cols={2}>
        <ListSubheader component="div"sx={style.typos}>Chat App
        <SocialIcon network="codepen" style={{ height: 30, width: 30, marginLeft: 5 }}/></ListSubheader>
      </ImageListItem>
      {chatapp.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=248&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            title={item.title}
            subtitle={item.author}
          />
        </ImageListItem>
      ))}
    </ImageList>
        </Grow>
        <br />
        <Grow in={checked}  style={{ transformOrigin: '0 0 0' }}
          {...(checked ? { timeout: 3000 } : {})}
        >
        <ImageList sx={{ width: '100%', height: 300 }}>
        <ImageListItem key="Subheader" cols={2}>
        <ListSubheader component="div"sx={style.typos}>Awake
        <SocialIcon network="codepen" style={{ height: 30, width: 30, marginLeft: 5 }}/></ListSubheader>
      </ImageListItem>
      {awake.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=248&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            title={item.title}
            subtitle={item.author}
          />
        </ImageListItem>
      ))}
    </ImageList>
        </Grow>
      </Box>
      
    </Box>
  );
}
