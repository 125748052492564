import React from 'react'
//router
import Router from './router';
//components

//redux
import { Provider } from "react-redux"
import store from "./redux/store";


export default function App() {
  return (
    <Provider store={store}>
      <Router />
    </Provider>
  )
}
