const theme = (isDarkMode)=> ({
    palette: {
        mode:isDarkMode ? "dark": "light",
        primary: {
          main: '#22062d',
        },
        secondary: {
          main: '#d7e9f7',
        },
        info: {
          main: '#2196f3',
        },
    
      },
  })
  export default theme;