import React from 'react'
//theme
import { ThemeProvider, createTheme } from '@mui/material'
import theme from '../utils/theme'
//router
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Index from '../components/index'

import { useSelector } from 'react-redux';
import Certificates from '../components/Certificates'
import Footer from '../components/Footer'
import Portfolio from '../components/Portfolio'
import Resume from '../components/Resume'

export default function RouterComponent() {
    const ui = useSelector((state) => state.ui);
    const THEME =createTheme(theme(ui.isDarkMode));

    return (
        <ThemeProvider theme={THEME}>
        <Router>
            <Switch>
                <Route component= {Index} path="/"/>
                <Route component= {Footer} path="/footer"/>
                <Route component= {Certificates} path="/certificates"/>
                <Route component= {Portfolio} path="/portfolio"/>
                <Route component= {Resume} path="/resume"/>
            </Switch>
        </Router>
    </ThemeProvider>
    )
}
