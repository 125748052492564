import React from 'react'
import { SocialIcon } from 'react-social-icons';
import { Grid,
         Box,
        Typography,
        Paper,
        Alert,
        Button,
        InputAdornment,
        Snackbar, 
        TextField,
        Divider,
        } from '@mui/material';
import TrapFocus from "@mui/material/Unstable_TrapFocus"
import { Email, SendSharp, } from '@mui/icons-material';
import map from "../assets/images/AH-bulacan.jpg"
import SettingsCell from '@mui/icons-material/SettingsCell';

const style = {
 mains:{
    backgroundColor:(theme)=> theme.palette.background.default,
    display:'flex',
    flexDirection:'column',
    justifyContent:'center'

  },
    container:{
        paddingTop:'40px',
        display:'flex',
        flexDirection:'row',
        justifyContent: "center",
        
    },
    text1:{
        paddingBottom:'40px',
        display:'flex',
        justifyContent: "center",
        color: theme => theme.palette.text.primary,
    },
        paper:{
            display: 'flex',
            flexWrap: 'center',
            alignItems:'center',
            '& > :not(style)': {
              m: 5,
              width: '30%',
              height: '30%',
            },
            
        },
        Box1:{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            paddingTop: '50px 0'
        },
        btn:{
            color: theme => theme.palette.text.primary,
            fontSize: 20,
            marginLeft: 2,
            borderRadius: 12,
            backgroundColor:(theme)=> theme.palette.background.default,
                fontFamily:'Irish Grover',
                "&:hover":{
                    color: theme => theme.palette.primary.main,
                },
            
        },
        text:{
            bgcolor: 'background.paper',
            borderColor: 'text.primary',
            m: 1,
            border: 1,
            width: '15rem',
            height: '10rem',
            borderRadius: '16px'
        },
        text2:{
            bgcolor: 'background.paper',
            borderColor: 'text.primary',
            m: 1,
            border: 1,
            width: '15rem',
            height: '2rem',
            borderRadius: '16px'
        },
        root:{
            bgcolor:'#889EAF',
            marginTop: 2,
            padding: 1,
        }
        
    }


export default function Footer() {
const [open, setOpen] = React.useState(false);
const[msg, setMsg]=React.useState(false);
 const handleClick = () => {
    setMsg(true);
      };
const handleClose = (event, reason) => {
     if (reason === 'clickaway') {
     return;
 }
    setMsg(false);
 };
    return (
      
        <Box component='span' sx={{flexGrow: 1}} >
             <Divider />
      <Box sx={style.mains}>      
      <Paper elevation={3}  sx={{...style.root}}>
      <Box sx={style.paper}>
        <Grid item xs>
        <Typography sx={{fontFamily:'Cutie-Zombie', fontWeight: 30, fontSize: 20,
        color: theme => theme.palette.primary.main}}>If you are Interested!,<br/>
          You are free to message me!  </Typography>
        <Box sx={style.Box1}>
        <Button  variant='contained'
        onClick={() => setOpen(true)}>
     Click &#10239;
      </Button><br/>
      {open && (
        <TrapFocus open>
          <Box tabIndex={-1} sx={{ mt: 1, p: 1 }}>
          <Box sx={{display:'flex',flexDirection: 'row', 
        justifyContent:'center', marginLeft: 13,}}> 
               <TextField
        id="input-with-icon-textfield"
        label="Email"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Email />
            </InputAdornment>
          ),
        }}
        variant='outlined'
        style={{ width: 280 }}
      /></Box>
            <br />
      
          <Box sx={{display:'flex',flexDirection: 'row', 
        justifyContent:'center', marginLeft: 13,}}> 
        <TextField 
        label="Message"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button><SendSharp 
              onClick={handleClick} /></Button>
            </InputAdornment>
          ),
        }}
        variant='outlined'
        style={{ width: 280,h: 3 }}
      /></Box>
            <br />
        <Box component="div" sx={{display:'flex',flexDirection: 'row', 
        justifyContent:'center', marginLeft: 10}}>
      <Snackbar open={msg} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Message Sent!
        </Alert>
      </Snackbar>
      <Button sx={{height: 30}} variant="contained" 
           onClick={() => setOpen(false)}>
        Close
      </Button>
    
      </Box>
      
          </Box>
        </TrapFocus>
        
      )}
      </Box>
     </Grid>
     <Grid item xs>
      <Box component='img' src={map}
     sx={{width: 500, height: 400,display:'flex'}}/> </Grid>

      <Grid item xs>
      <Typography sx={{fontFamily: 'BreeSerif', fontSize: 18, 
     }}> Performance-driven and motivated Full Stack Developer with 2+ years of extensive experience in participating in all phases of software development. Adept at managing and supervising other employees and determined to achieve extraordinary results. Possess a strong attention to detail, well-developed time management skills,
         and ability to complete all projects within schedule and in a timely manner</Typography>
      <br /> <br />
      <Typography>  <SettingsCell style={{ height: 20, width: 25,}} /> 0947 - 9898 - 113 </Typography>
      <Typography>  <Email style={{ height: 20, width: 25,}} /> mariaemerlitaorbiso21@gmail.com</Typography>
     
      </Grid>
     
     </Box>
          </Paper>
     
   
   
            </Box>
                <Grid sx={style.container}>
                  <SocialIcon bgColor='#99A799'style={{ height: 40, width: 40,marginRight:3 }} network="twitter" />
                  <SocialIcon bgColor='#99A799'style={{ height: 40, width: 40,marginRight:3 }}  url="https://www.facebook.com/mariaaaemm/" network="facebook"/>
                  <SocialIcon bgColor='#99A799'style={{ height: 40, width: 40,marginRight:3 }} network="instagram"/>
                  <SocialIcon  bgColor='#99A799'style={{ height: 40, width: 40,marginRight:3 }} url="https://github.com/emrbs3" network="github"/>
                  <SocialIcon  bgColor='#99A799'style={{ height: 40, width: 40,marginRight:3 }} url="https://mail.google.com" network="google"/>
                  <SocialIcon  bgColor='#99A799'style={{ height: 40, width: 40,marginRight:3 }} network="whatsapp"/>
                  <SocialIcon bgColor='#99A799'style={{ height: 40, width: 40,marginRight:3 }}  network="tiktok"/>
              </Grid>
              <Typography sx={style.text1}>
            &copy; 2021 EmRbs</Typography>
        </Box>
    )
}
